.croppable {
  position: relative;
  background-color: #eee;
  margin-bottom: 30px;
}

.btn-croppable {
  position: absolute;
  left: 0;
  bottom: -30px;
}

input[type=range] {
  background: transparent;
  background-color: #eee;
  -webkit-appearance: none;
  height: 6px;
  margin: 10px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #999;
  cursor: pointer;
  margin-top: 0; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  border: none;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #999;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  border: none;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #999;
  cursor: pointer;
}

.cropit-image-zoom-input {
  position: absolute;
  right: 30px;
  bottom: -30px;
  width: 120px;
  max-width: 120px;
  margin-top: 5px;

  &:before {
    content: "\f03e";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #999;
    font-size: 14px;
    position: absolute;
    top: -7px;
    left: -30px;
  }

  &:after {
    content: "\f03e";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #999;
    font-size: 24px;
    position: absolute;
    top: -12px;
    left: 135px;
  }
}

input.cropit-image-input {
  visibility: hidden;
  position: absolute;
  left:0;
  top:0;
}
